import React from 'react';
import hero_picture from '../../../../assets/images/pictures/hero_picture.svg';
import logo_apple from '../../../../assets/images/logos/logo_apple.svg';
import logo_android from '../../../../assets/images/logos/logo_android.svg';
import double_coin_bottom_left from '../../../../assets/images/pictures/double_coin_bottom_left.svg';
const Hero = () => {
    return (
        <div className="hero">
            <div className="background-bottom-left">
                <img src={double_coin_bottom_left} alt="Background" className="background-image"/>
            </div>
            <div className="hero-content">
                <h1>Une application pour faciliter la gestion de vos cautions </h1>
                <h2>"Cotious" est une application mobile innovante conçue pour simplifier la gestion des cautions pour
                    les locataires et les propriétaires. Avec une interface utilisateur épurée et intuitive, "Cotious"
                    permet de suivre facilement les dépôts de garantie, de la création du compte caution à son
                    remboursement.
                    Les utilisateurs peuvent ajouter et gérer plusieurs cautions, en saisissant des détails essentiels
                    comme le montant, la date de dépôt, et les informations du bailleur ou du locataire. </h2>
                <div className="buttons">
                    <a href="https://apple.com" className="btn">
                        <img src={logo_apple} alt="Apple Logo"/>
                        iOS
                    </a>
                    <a href="https://android.com" className="btn">
                        <img src={logo_android} alt="Android Logo"/>
                        Android
                    </a>
                </div>
            </div>
            <div className="hero-image">
                <img src={hero_picture} alt="Hero"/>
            </div>
        </div>
    )
};

export default Hero;
