import React from 'react';
import cotious_logo from '../../../../assets/images/logos/cotious_blue.svg';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-top">
                <div className="footer-left">
                    <div className="footer-logo">
                        <img src={cotious_logo} alt="Logo"/>
                    </div>
                    <div className="footer-info">
                        <p>Adresse: 48 Rue de Varenne, 75007 Paris, France</p>
                    </div>
                </div>
                <div className="footer-right">
                    <FooterCategory
                        title="Réseaux sociaux"
                        links={[
                            {text: 'Facebook', url: 'https://www.facebook.com' },
                            { text: 'Twitter', url: 'https://www.twitter.com' },
                            { text: 'LinkedIn', url: 'https://www.linkedin.com' }
                        ]}
                    />
                    <FooterCategory
                        title="Informations"
                        links={[
                            { text: 'À propos', url: '#' },
                            { text: 'Contact', url: '#' },
                            { text: 'Mentions légales', url: '#' }
                        ]}
                    />
                </div>
            </div>
            <div className="footer-bottom">
                <p>© 2024 Tous droits réservés. Made by HEX-Agency</p>
            </div>
        </footer>
    );
};

const FooterCategory = ({ title, links }) => (
    <div className="footer-category">
        <h4>{title}</h4>
        <ul>
            {links.map((link, index) => (
                <li key={index}><a href={link.url} target="_blank">{link.text}</a></li>
            ))}
        </ul>
    </div>
);

export default Footer;
