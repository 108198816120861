import React from 'react';
import Hero from "./Hero";
import Navbar from "../../Layouts/components/Navbar";
import right_top_cloud from '../../../../assets/images/background_pictures/right_top_cloud.svg';
import hero_bottom_cloud from '../../../../assets/images/background_pictures/hero_bottom_cloud.svg';
import right_bottom_how_to_use_cloud from '../../../../assets/images/background_pictures/right_bottom_how_to_use_cloud.svg';
import HowToUse from "./HowToUse";
import Optimized from "./Optimized";
import Launch from "./Launch";
import Footer from "../../Layouts/components/Footer";

const Home = () => {
    return <>
        <div className="background-top-cloud">
            <img src={right_top_cloud} alt="Background" className="background-image"/>
        </div>
        <Navbar/>
        <Hero/>
        <div className="background-left-middle-hero-cloud">
            <img src={hero_bottom_cloud} alt="Background" className="background-image"/>
        </div>
        <HowToUse/>
        <div className="background-right-bottom-how-to-use-cloud">
            <img src={right_bottom_how_to_use_cloud} alt="Background" className="background-image"/>
        </div>
        <Optimized/>
        <Launch/>
    </>

}
export default Home;
