import React, { useState } from 'react';
import logo from '../../../../assets/images/logos/cotious_blue.svg';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <nav className="website_navbar">
            <div className="logo">
                <img src={logo} alt="Logo" />
            </div>
            <div className="menu_icon" onClick={toggleMenu}>
                ☰
            </div>
            <div className={`nav_links ${menuOpen ? 'open' : ''}`}>
                <ul>
                    <li><a href="#">Se connecter</a></li>
                    <li><a href="#" className="cta">Démarrer sur cotious</a></li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
