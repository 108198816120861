import React from 'react';
import double_coin_bottom_left_green_inversed from '../../../../assets/images/pictures/double_coint_bottom_left_green_inversed.svg';
import coin_top_right_blue from '../../../../assets/images/pictures/coin_top_right_blue.svg';
import optimized_picture from "../../../../assets/images/pictures/optimized_picture.svg";

const Optimized = () => {

    return (
        <>
            <h2 className="h2_title_center" data-aos="fadeInUp">Gestion optimisé à porté de main</h2>
            <div className="optimized">
                <div className="optimized-background-bottom-left">
                    <img src={double_coin_bottom_left_green_inversed} alt="Background" className="background-image" data-aos="zoomIn" />
                </div>
                <div className="optimized-background-top-right">
                    <img src={coin_top_right_blue} alt="Background" className="background-image" data-aos="zoomIn" />
                </div>
                <div className="optimized-content" data-aos="fadeInLeft">
                    <h3>Ajoute ton bien immobilier et gère la gestion des cautions</h3>
                    <p>Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions.</p>
                </div>
                <div className="optimized-image" data-aos="fadeInRight">
                    <img src={optimized_picture} alt="Optimized" />
                </div>
            </div>
        </>
    );
}

export default Optimized;
