import React from 'react';
import double_coin_bottom_left_green from '../../../../assets/images/pictures/double_coin_bottom_left_green.svg';
import coin_top_right_green from '../../../../assets/images/pictures/coin_top_right_green.svg';
import how_to_use_picture from "../../../../assets/images/pictures/how_to_use_picture.svg";

const HowToUse = () => {
    return (
        <>
            <h2 className="h2_title_center" data-aos="fadeInUp">Comment ça marche ?</h2>
            <div className="how-to-use">
                <div className="how-to-use-background-bottom-left">
                    <img src={double_coin_bottom_left_green} alt="Background" className="background-image" data-aos="zoomIn" />
                </div>
                <div className="how-to-use-background-top-right">
                    <img src={coin_top_right_green} alt="Background" className="background-image" data-aos="zoomIn" />
                </div>
                <div className="how-to-use-content" data-aos="fadeInLeft">
                    <h3>Ajoute ton bien immobilier et gère la gestion des cautions</h3>
                    <p>Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions le plus facilement. Une application pour gérer vos cautions.</p>
                </div>
                <div className="how-to-use-image" data-aos="fadeInRight">
                    <img src={how_to_use_picture} alt="How To Use" />
                </div>
            </div>
        </>
    );
}

export default HowToUse;
