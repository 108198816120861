import React from 'react';

const Launch = () => {
    return (
        <div className="launch">

            <div className="launch-content" data-aos="fadeInLeft">
                <h3>Commencer sur Cotious, c'est facile</h3>
            </div>
            <div className="launch-right" data-aos="fadeInRight">
                <ul>
                    <li>
                        <h4>1. Parlez-nous de votre logement</h4>
                        <p>Donnez-nous quelques informations de base, par exemple indiquez-nous où il se trouve et combien de voyageurs il peut accueillir.</p>
                    </li>
                    <li>
                        <h4>2. Faites en sorte de vous démarquer</h4>
                        <p>Ajoutez au moins 5 photos, un titre et une description. Nous allons vous aider.</p>
                    </li>
                </ul>
            </div>
        </div>
    )
};

export default Launch;
