import Turbolinks from "turbolinks";
import ReactOnRails from 'react-on-rails';

Turbolinks.start();

import animateOnScroll from '../scripts/animateOnScroll';

document.addEventListener('turbolinks:load', () => {

    setTimeout(() => {
        animateOnScroll();
    }, 300);
});


import Navbar from '../bundles/Layouts/components/Navbar';
import Home from '../bundles/Home/components/Home';
import Footer from '../bundles/Layouts/components/Footer';

ReactOnRails.register({
    Navbar,
    Home,
    Footer,
});
